import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core';

import config from 'config';
import Analytics from 'components/Analytics';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'styles/style.css';

fontAwesomeConfig.autoAddCss = false;

class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props;

    return (
      <>
        <Head>
          <meta charSet="utf-8" key="charset" />
          <meta
            key="viewport"
            name="viewport"
            content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no,shrink-to-fit=no"
          />
          <link rel="icon" href="/favicon.png" type="image/png" />
          <link rel="shortcut icon" href="/favicon.png" type="image/png" />
          <link rel="stylesheet" href="https://use.typekit.net/rwa2cix.css" />
        </Head>
        {config('/env') === 'production' && (
          <Analytics trackingId={config('/googleAnalytics/trackingId')} />
        )}
        <Component {...pageProps} />
      </>
    );
  }
}

export default MyApp;
