import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import ReactGA from 'react-ga';

const Analytics = ({ trackingId }) => {
  const { asPath } = useRouter();
  ReactGA.initialize(trackingId);

  useEffect(() => {
    ReactGA.pageview(asPath);
  }, [asPath]);

  return null;
};

Analytics.propTypes = {
  trackingId: PropTypes.string.isRequired,
};

export default Analytics;
